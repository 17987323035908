<template>
  <div class="container">
    <div class="card overflow-y overlapping-container has-top-shadow">
      <div v-if="adventureTeam && (adventureTeam.hasSearchableItems || adventureTeam.allowTeamPositionsView)" class="card-content pb-6 px-4 pt-1">
        <CardNavigation/>
        <ViewsStat :pageDetails="{pageName: 'scavenge'}"/>
        <div class="container has-text-centered pt-4 pb-2">
          <div class="is-size-5 has-text-weight-semibold has-baskerville-font has-text-brand-color">
            Map
          </div>
        </div>
        <div class="container has-text-centered">
          <div v-if="adventureTeam.hasSearchableItems" class="is-size-6 has-text-grey mb-2 px-4">
            Search or scan for items near you, by using your device's location, which may give clues to help with your current tasks. Items found will appear in your inventory.
          </div>
          <div v-if="adventureTeam.allowTeamPositionsView" class="is-size-6 has-text-grey mb-4 px-4">
            See the locations of other players
          </div>
          <div>
            <task-map key="team-map" :mapHeight="350"/>
            <div v-if="!showItemFoundMessage && !showItemNotFoundMessage" class="is-size-6 has-text-grey mb-5 px-4 has-text-centered">
              <div v-if="!userLocation || !userLocation.lat">
                <a
                @click="getLocation()"
                class="button has-text-weight-semibold is-large is-primary"
                v-bind:class="{'is-loading': loading}"
                >
                  <i class="fas fa-street-view mr-2"></i>
                  Get current location
                </a>
                <div class="container has-text-centered my-2">
                  Your location data is immediately deleted after scavenging.
                </div>
              </div>
              <a
              v-if="adventureTeam.hasSearchableItems && userLocation && userLocation.lat"
              @click="scavenge()"
              class="button has-text-weight-semibold is-large is-primary"
              v-bind:class="{'is-loading': loading}"
              :disabled="!allowScavengeLocation"
              >
                <i class="fas fa-search-location mr-2"></i>
                Scan & Search
              </a>
            </div>
            <div v-if="showItemFoundMessage || showItemNotFoundMessage" class="is-size-6 has-text-grey mb-5 px-4 has-text-centered">
              <div v-if="showItemFoundMessage" class="container has-text-centered mt-2">
                <b>Something found!</b> Go to your <i class="fas fa-sm fa-box ml-1"></i> Inventory to see it.
              </div>
              <div v-if="showItemNotFoundMessage" class="container has-text-centered my-2">
                Nothing found nearby, try again at another location.
              </div>
              <a
              v-if="!showItemFoundMessage"
              @click="scavenge()"
              class="button has-text-weight-semibold is-large is-primary"
              v-bind:class="{'is-loading': loading}"
              :disabled="!allowScavengeLocation"
              >
                <i class="fas fa-search-location mr-2"></i>
                Scan & Search
              </a>
              <a
              v-if="showItemFoundMessage"
              @click="$router.push({ name: 'Inventory' })"
              class="button has-text-weight-semibold is-large is-success"
              >
                <i class="fas fa-box mr-2"></i>
                Go to Inventory
              </a>
            </div>
          </div>
        </div>
        <div class="container has-text-centered mt-5">
          <div @click="$router.push({ name: 'Stage' })" class="button is-small is-primary is-rounded">
            <i class="fas fa-clipboard mr-2"></i>
            Back to {{ $store.state.uiMods.task.toLowerCase() }}s page
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardNavigation from '@/components/CardNavigation'
import firebaseApp from '@/firebase/init'
import TaskMap from '@/components/TaskMap.vue'
import ViewsStat from '@/components/ViewsStat.vue'

export default {
  name: 'MapView',
  components: {
    CardNavigation,
    TaskMap,
    ViewsStat
  },
  data(){
    return {
      showItemFoundMessage: false,
      showItemNotFoundMessage: false,
      loading: false,
      key: 0
    }
  },
  methods: {
    getLocation() {
      this.loading = true
      const now = new Date()
      if(
        !this.$store.state.userLocation ||
        this.$store.state.userLocation && (now.getTime() - this.$store.state.userLocationExpiryTimestamp.getTime())/1000 > 60
      ){
        this.$store.dispatch('getUserLocationAction', this.t)
      }
      setTimeout(() => { this.loading = false }, 1500)
      this.key += 1
    },
    scavenge() {
      if(!this.loading && this.allowScavengeLocation){
        this.showItemFoundMessage = false
        this.showItemNotFoundMessage = false
        this.loading = true
        const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunction')
        const position = this.userLocation ? { lat: this.userLocation.lat, lon: this.userLocation.lon } : null
        masterFunction({
          methodName: 'search',
          teamCode: this.$store.state.teamCode,
          userName: this.$store.state.userName,
          position
        }).then(res => {
          this.loading = false
          if(res.data.foundItems.length > 0) {
            this.showItemFoundMessage = true
          } else {
            if(res.data.foundItems.length === 0) {
              this.showItemNotFoundMessage = true
            }
          }
        }).catch(err => {
          this.loading = false
        })
      }
    },
  },
  watch: {
    userLocation() {
      this.showItemFoundMessage = false
      this.showItemNotFoundMessage = false
      this.key += 1
    }
  },
  computed: {
    userLocation() {
      return this.$store.state.userLocation
    },
    adventureTeam() {
      return this.$store.state.adventureTeam
    },
    allowScavengeLocation() {
      return this.userLocation && this.userLocation.lat && this.userLocation.lon
    }
  },
  mounted() {
    if (
      this.adventureTeam &&
      (!this.adventureTeam.hasSearchableItems && !this.adventureTeam.allowTeamPositionsView))
    {
      this.$router.push({ name: 'Stage' })
    }

    this.$store.dispatch('enterPage', {
      pageName: 'mapView'
    })

    this.getLocation()
  }
}
</script>
